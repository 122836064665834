import { socket } from './socket';
import axios from 'axios';
import { environment } from '../../../environment';
import { toast } from 'react-toastify';
import * as auth from './auth';
import * as diag from './diagnostic';
import * as user from './user';

export const instance = axios.create({
    baseURL: environment.apiUrl,
    timeout: 5000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
});

instance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;

    return config;
});
  
instance.interceptors.response.use((response) => {
    return response
    }, (error) => {
    switch(error.response.data.statusCode) {
        case 400:
            let msg = 'Mauvaise requete';

            if(!!error.response.data['message']) {
                msg = Array.isArray(error.response.data['message']) ? error.response.data['message'][0] : error.response.data['message'];
            }

            toast.error(msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        break;
        case 401:
            if(window.location.href !== environment.frontUrl) {
                window.location.href = '/';
            }
        break;
        default:
        break;
    }

    return Promise.reject(error)
});

export default {
    auth,
    diag,
    socket,
    user
}