import React, { useEffect, useState } from "react";
import './FilterButton.scss';

export function FilterButton({children, isActivated, name, color, changeHandler}: any) {
    const [activated, setActivated] = useState(false);

    useEffect(() => {
        setActivated(isActivated);
    }, []);

    const handler = (event: any) => {
        setActivated(!activated);

        changeHandler(event);
    }

    return (
        <button type="button" onClick={handler}
        style={{
            backgroundColor: color,
            borderColor: color,
            color: activated ? '#fff' : color
          }}
        name={name}
        className={"btn " + (activated ? "" : "outlined")}>
            {children}
        </button>
    )
}