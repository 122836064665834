import { instance } from '.';
import * as storage from '../storage';

export const getAll = async () => {
    return instance.get(`users`);
}

export const create = async (data: any) => {
    return instance.post(`users`, data);
}

export const update = async (data: any) => {
    return instance.put(`users/${data['_id']}`, data);
}

export const deleteOne = async (id: string) => {
    return instance.delete(`users/${id}`);
}

export const getOne = async (id: string) => {
    return instance.get(`users/${id}`);
}

export const me = async () => {
    const me = await instance.get(`users/me`);

    storage.set('user', JSON.stringify(me.data));

    return me.data;    
}