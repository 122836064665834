import { CircularProgress } from "@material-ui/core";
import React from 'react';

export const getAuth0ScoreColor = (score: number): string | undefined => {
  switch (score) {
    case 0:
      return "green";
    case -1:
      return "orange";
    case -2:
    case -3:
      return "red";
  }
};

export const getDiagRealName = (type: string): string | undefined => {
  switch (type) {
    case "ips":
      return "Interfaces IP";
    case "upTime":
      return "upTime";
    case "cpuInfo":
      return "Processeurs";
    case "memInfo":
      return "Infos mémoires";
    case "resolve":
      return "Resolution DNS";
    case "ping":
    case "pingIp":
      return "Ping";
    case "whois":
    case "whoisIp":
      return "Whois";
    case "digTxt":
      return "Info SPF";
    case "digMx":
      return "Info MX";
    case "digNs":
      return "Info NS";
    case "whatCMS":
      return "Check CMS";
    case "observatory":
      return "Check Security WEB";
    case "hstsPreload":
      return "Check HSTS";
    case "sslLabs":
      return "Check SSLLabs";
    case "headers":
      return "En-têtes et redirections";
    case "traceroute":
    case "tracerouteIp":
      return "Traceroute";
    case "dnsblLookup":
    case "dnsblLookupIp":
      return "Check blacklist";
    case "signalAuth0":
    case "signalAuth0Ip":
      return "Scan ports";
    case "gtMetrix":
      return "Performances WEB";
    case "reverse":
      return "Check NSlookup";
    case "openPorts":
      return "Ports ouverts";
  }
};

export const getDiagColor = (type: string): string | undefined => {
  switch (type) {
    // Serveur linux
    case "ips":
    case "upTime":
    case "cpuInfo":
    case "memInfo":
      return "#2980b9";

    // Analyse DNS
    case "resolve":
    case "whois":
    case "whoisIp":
    case "digTxt":
    case "digMx":
    case "digNs":
      return "#2980b9";

    // Analyse réseau
    case "ping":
    case "pingIp":
    case "traceroute":
    case "tracerouteIp":
      return "#8e44ad";

    // Compliance WEB
    case "whatCMS":
    case "observatory":
    case "hstsPreload":
    case "sslLabs":
    case "headers":
      return "#c0392b";

    // Sécurité
    case "dnsblLookup":
    case "dnsblLookupIp":
    case "signalAuth0":
    case "signalAuth0Ip":
    case "reverse":
    case "openPorts":
      return "#d35400";

    // Performance
    case "gtMetrix":
      return "#27ae60";

  }
}
