import React from 'react';
import MaterialTable from 'material-table';
const moment = require('moment');

export default function UsersTable({data, updateUser, addUser, deleteUser}: any) {
  const columns: any = [
    { title: 'Login', field: 'username' },
    { 
      title: 'Mot de passe',
      field: 'password',
      hideFilterIcon: true,
      editable: 'onAdd',
      emptyValue: '********'
    },
    {
      title: 'Administrateur',
      field: 'isAdmin',
      lookup: { 'true': 'Oui', 'false': 'Non' },
      initialEditValue: 'false'
    },
    { 
      title:'Créé le',
      field: 'created_at',
      editable: 'never',
      render: (value: any) => moment(value).utc(2).format('DD/MM/YYYY')
    },
    { 
      title: 'Moidifié le',
      field: 'updated_at',
      editable: 'never',
      render: (value: any) => moment(value).utc(2).format('DD/MM/YYYY')
    },
  ]

  return (
    <MaterialTable
      title="Utilisateurs"
      columns={columns}
      data={data}
      editable={{
        onRowAdd: (newData) => addUser(newData),
        onRowUpdate: (newData, oldData) => updateUser(newData),
        onRowDelete: (oldData) => deleteUser(oldData)
      }}
    />
  );
}
