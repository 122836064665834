import React, { useEffect, useState } from "react";
import "./FilterButton.scss";
import {
  Modal,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

export function ConfirmationModal({modalParameters, toggleModal}: any) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(toggleModal);
  }, [toggleModal])

  const openHandler = () => {
    modalParameters.callback();
    setOpen(false);
  };

  const closeHandler = () => {
    modalParameters.closeModal();
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeHandler}>
        <DialogTitle id="alert-dialog-title">
          {modalParameters.header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalParameters.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeHandler} color="primary">
            Non
          </Button>
          <Button onClick={openHandler} color="primary">
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
