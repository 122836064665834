import React from 'react';
import { Typography, Theme, makeStyles, createStyles, Card, CardContent } from '@material-ui/core';
import './DiagnosticsComponents.scss';
import Domain from './Domain';
import Address from './Address';
import Ssh from './Ssh';

import { DiagnosticType } from '../../../shared/services/enums'

const moment = require('moment');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: '10px'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  }),
);

export default function Diagnostic({diagnostic}: any) {
    const classes = useStyles();

    const displayDiagnostic = (type: string) => {
        switch(type) {
            case DiagnosticType.Domain:
                return <Domain diagnostic={diagnostic} />;
            case DiagnosticType.Address:
                return <Address diagnostic={diagnostic} />;
            case DiagnosticType.SSH:
              return <Ssh diagnostic={diagnostic} />;
        }
    }

    return (
        <div className="domain">
          {!!diagnostic && 
           <>
            <Card className={classes.root}>
                <CardContent>
                <Typography variant="h5" component="h2">
                    {diagnostic.host}
                </Typography>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    <span>{diagnostic.description}</span><br></br>
                    Diagnostiqué le : {moment(diagnostic.created_at).utc(2).format('DD/MM/YYYY HH:mm:ss')}
                </Typography>
                </CardContent>
            </Card>

            {diagnostic?.type && displayDiagnostic(diagnostic.type)}
          </>
        }
      </div>
    );
} 
