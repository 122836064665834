import React from 'react';
import { CircularProgress, Divider } from '@material-ui/core';
import './DiagnosticsComponents.scss';
import { Panel } from '../../../shared/ui/components/Panel';
import { getAuth0ScoreColor } from '../../../shared/helpers';
const { getDomain } = require('tldjs');
const moment = require('moment');

export default function Domain({diagnostic}: any) {
  const registrarOrExpir = (text: string): boolean => {
    return (!!text.toUpperCase().match(/REGISTRAR/g)) || (!!text.toUpperCase().match(/EXPIR/g));
  }

  const spf = (text: string): boolean => {
    return (!!text.toUpperCase().match(/SPF/g));
  }

  const niceBytes = (x: string) => {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    let l = 0, n = parseInt(x, 10) || 0;

    while(n >= 1024 && ++l){
        n = n/1024;
    }

    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }

  const getStatus = (status: string) => {
    if(status === 'SUCCESS') {
        return <i className="green">&#10003;</i>;
    } else if(status === 'PENDING') {
        return <CircularProgress size={20} disableShrink/>;
    } else if(status === 'FAILURE'){
       return <i className="red">&#x292C;</i>;
    }
}

  return (
      <div className="domain">

        <Panel title="Analyse DNS">
          {diagnostic?.tasks?.digNs && <h3><b>{getStatus(diagnostic?.tasks['digNs'])} Serveur DNS</b></h3>}
          {!!diagnostic?.domain?.dig_ns && 
            <>
              {diagnostic.domain.dig_ns.map((value: any, i: number) => {
                return <p className="m-0" key={i}><b>Serveur DNS {i} : </b>{value}</p>
              })}<br/>

               <Divider />
            </>
          }

          {diagnostic?.tasks?.resolve && <h3><b>{getStatus(diagnostic?.tasks['resolve'])} Résolution DNS</b></h3>}
          {!!diagnostic?.domain?.resolve &&
            <>
            {!!diagnostic?.domain?.resolve?.domain &&
              <div>
                <p className="columns">
                  <b>Domaine</b>
                  {diagnostic?.domain?.resolve['domain']?.ipv4s.length > 0 && 
                    <>
                      {Object.values(diagnostic?.domain?.resolve['domain']?.ipv4s).map((value: any, key: any) => {
                        return <span key={key}><b>IPv4 : </b>{value?.address} | <b>TTL : </b>{value.ttl}</span>
                      })}
                    </>
                  }

                  {diagnostic?.domain?.resolve['domain']?.ipv6s.length > 0 &&
                    <>
                      {Object.values(diagnostic?.domain?.resolve['domain']?.ipv6s).map((value: any, key: any) => {
                        return <span key={key}><b>IPv6 : </b>{value?.address} | <b>TTL : </b>{value?.ttl}</span>

                      })}
                    </>
                  }
                </p>
              </div>
            }

            {!!diagnostic?.domain?.resolve?.subdomain &&
              <div>
                <p className="columns">
                  <b>Sous domaine</b>
                  {diagnostic.domain.resolve['subdomain']?.ipv4s.length > 0 && 
                    <>
                      {Object.values(diagnostic?.domain?.resolve['subdomain']?.ipv4s).map((value: any, key: any) => {
                        return <span key={key}><b>IPv4 : </b>{value?.address} | <b>TTL : </b>{value.ttl}</span>
                      })}
                    </>
                  }

                  {diagnostic.domain.resolve['subdomain']?.ipv6s.length > 0 && 
                    <>
                      {Object.values(diagnostic?.domain?.resolve['subdomain']?.ipv6s).map((value: any, key: any) => {
                        return <span key={key}><b>IPv6 : </b>{value?.address} | <b>TTL : </b>{value.ttl}</span>
                      })}
                    </>
                  }
                </p>
              </div>
            }

            <Divider />
            </>
          }

          {diagnostic?.tasks?.digMx && <h3><b>{getStatus(diagnostic?.tasks['digMx'])} Serveur MX (mails)</b></h3>}
          {!!diagnostic?.domain?.dig_mx &&
            <>
              {diagnostic?.domain?.dig_mx?.map((value: any, i: number) => {
                return <p className="m-0" key={i}><b>Serveur MX {i}, priorité {value.priority} : </b>{value.exchange}</p>
              })}<br/>

              <Divider />
            </>
          }

          {diagnostic?.tasks?.digTxt && <h3><b>{getStatus(diagnostic?.tasks['digTxt'])} SPF</b></h3>}
          {!!diagnostic?.domain?.dig_txt &&
            <>
              {Object.values(diagnostic.domain.dig_txt).map((value: any, key: any) => {
                return <div key={key}>{spf(value[0])
                  && <p>{value[0]}</p>
                }</div>
              })}

              <Divider />
            </>
          }

          {diagnostic?.tasks?.whois && <h3><b>{getStatus(diagnostic?.tasks['whois'])} Informations du domaine (WHOIS)</b></h3>}
          {!!diagnostic?.whois &&
            <>
              {Object.entries(diagnostic.whois).map((value: any, key: any) => {
                return <div key={key}>{registrarOrExpir(value[0])
                  && <p className="m-0"><b>{value[0]} :</b> {value[1]}</p>
                }</div>
              })}
            </>
          }
        </Panel>

        <Panel title="Analyse réseau">
          {diagnostic?.tasks?.ping && <h3><b>{getStatus(diagnostic?.tasks['ping'])} Ping</b></h3>}
          {!!diagnostic?.ping && 
            <>
              {!!diagnostic?.ping?.v4 && 
                <>
                  {Object.values(diagnostic?.ping?.v4).map((value: any, key: any) => {
                    return <p key={key} className="m-0"><b>IPv4 : </b>{value.host} | ping : {value.ttl}ms</p>
                  })}
                </>
              }

              {!!diagnostic?.ping?.v6 && 
                <>
                  {Object.values(diagnostic?.ping?.v6).map((value: any, key: any) => {
                    return <p key={key} className="m-0"><b>IPv6 : </b>{value.host}</p>
                  })}
                </>
              }

              {diagnostic?.ping?.v6.length === 0 && <p>Aucune IPv6</p>}

              <Divider />

              </>
          }

          {diagnostic?.tasks?.traceroute && <h3><b>{getStatus(diagnostic?.tasks['traceroute'])} Traceroute</b></h3>}
          {!!diagnostic?.domain?.traceroute && 
            <>
              {!!diagnostic?.domain?.traceroute && 
                <>
                  {Object.values(diagnostic.domain.traceroute).map((value: any, key: any) => {
                    const { hop, ip, rtt1 } = value;

                    return <p key={key} className="m-0"><b>hop : </b>{hop} | <b>IP : </b> {ip} | <b>temps : </b> {rtt1}</p>
                  })}
                </>
              }
            </>
          }

        </Panel>

        <Panel title="Compliance WEB">

          {diagnostic?.tasks?.headers && <h3><b>{getStatus(diagnostic?.tasks['headers'])} Headers</b></h3>}
          {!!diagnostic?.domain?.headers?.domain &&
            <>
              <p><b>Protocol WEB : </b>http {diagnostic.domain?.headers?.domain.httpVersion} (FAUX)</p>
              <p><b>Serveur WEB : </b>{diagnostic.domain?.headers?.domain.server}</p>
            </>
          }

          {!!diagnostic?.domain?.headers &&
              <>
                  <h3><b>Redirections</b></h3>
                  {!!diagnostic?.domain?.headers?.domain &&
                  <p><a target="_blank" href={diagnostic.host}>{diagnostic.host}</a> redirection {diagnostic.domain?.headers?.domain.statusCode} vers <a target="_blank" href={diagnostic.domain?.headers?.domain.location}>{diagnostic.domain?.headers?.domain.location}</a></p>}
                  {!!diagnostic?.domain?.headers?.subdomain &&
                  <p><a target="_blank" href={getDomain(diagnostic.host)}>{getDomain(diagnostic.host)}</a> redirection {diagnostic.domain?.headers?.subdomain.statusCode} vers <a target="_blank" href={diagnostic.domain?.headers?.subdomain.location}>{diagnostic.domain?.headers?.subdomain.location}</a></p>}
                  
                  <Divider />
              </>
          }

          {diagnostic?.tasks?.hstsPreload && <h3><b>{getStatus(diagnostic?.tasks['hstsPreload'])} Vérification HSTS</b></h3>}
          {!!diagnostic?.domain?.hstsPreload &&
            <>
              {Object.entries(diagnostic.domain.hstsPreload).map((value: any, key: any) => {
                return <p key={key}>
                  {value[1]?.hasOwnProperty('include_subdomains') &&
                    <span className="columns">
                      <span><b>Status {value[0]} :</b> {value[1].include_subdomains && value[1].present ? <i className="green">&#10003;</i> : <i className="red">&#x292C;</i>}</span>
                      <span><b>URL :</b> <a target="_blank" href={"https://hstspreload.org/?domain=" + diagnostic.domain.hstsPreload['domain']}>https://hstspreload.org/?domain={diagnostic.domain.hstsPreload['domain']}</a></span>                                     
                    </span>
                  }
                  {(!value[1]?.hasOwnProperty('include_subdomains') && value[0] !== 'domain') &&
                    <span className="columns">
                      <span><b>{value[0]} : </b><i className="red">&#x292C;</i></span>                                    
                    </span>
                  }
                </p>
              })}

              <Divider />
            </>
          }
         
            {diagnostic?.tasks?.whatCMS && <h3><b>{getStatus(diagnostic?.tasks['whatCMS'])} CMS utilisé</b></h3>}
            {!!diagnostic?.domain?.whatCMS &&
              <>
                {diagnostic?.domain?.whatCMS?.code !== 200 && <p><b></b>{diagnostic?.domain?.whatCMS?.msg}</p>}
                {!!diagnostic?.domain?.whatCMS?.name && <p><b>Nom : </b>{diagnostic.domain.whatCMS['name']}</p>}
                {!!diagnostic?.domain?.whatCMS?.version && <p><b>Version : </b>{diagnostic.domain.whatCMS['version']}</p>}
                
                <Divider />
              </>
            }  

            {diagnostic?.tasks?.sslLabs && <h3><b>{getStatus(diagnostic?.tasks['sslLabs'])} Evaluation du certificat SSL</b></h3>}
            {!!diagnostic?.domain?.sslLabs &&
              <>
                {!!diagnostic?.domain?.sslLabs?.statusMessage && <p>{diagnostic?.domain?.sslLabs?.statusMessage}</p>}
                {!!diagnostic?.domain?.sslLabs?.endpoints &&
                Object.entries(diagnostic.domain.sslLabs.endpoints).map((value: any, key: any) => {
                    return <p className="columns" key={key}>
                      {diagnostic.domain?.sslLabs['endpoints'][key].serverName && <span><b>Serveur :</b> {diagnostic.domain?.sslLabs['endpoints'][key].serverName}</span>} 
                      <span><b>Progression :</b> {diagnostic.domain?.sslLabs['endpoints'][key].progress < 100 && <CircularProgress size={20} disableShrink/>} {diagnostic.domain?.sslLabs['endpoints'][key].progress || 0}%</span>
                      <span><b>IP :</b> {diagnostic.domain?.sslLabs['endpoints'][key].ipAddress}</span>
                      <span><b>Note :</b> {diagnostic.domain?.sslLabs['endpoints'][key].grade}</span>
                      <span><b>Warning :</b> {diagnostic.domain?.sslLabs['endpoints'][key].hasWarnings ? 'Oui' : 'Non'}</span>                    
                    </p>
                })}

                <Divider />
              </>
            }  

            {diagnostic?.tasks?.observatory && <h3><b>{getStatus(diagnostic?.tasks['observatory'])} Mozilla Observatory</b></h3>}
            {!!diagnostic?.domain?.observatory &&
              <>
                {!!diagnostic?.domain?.observatory?.simple && 
                  <>
                      <p><b>Score de confiance :</b> {diagnostic?.domain?.observatory?.simple?.score || 0}/100</p>
                      <p><b>Tests réussis :</b> {diagnostic?.domain?.observatory?.simple?.tests_passed}/{diagnostic?.domain?.observatory?.simple?.tests_quantity}</p>
                  </>
                }

                {!!diagnostic?.domain?.observatory?.advanced &&
                  <table className="table-border">
                    <thead>
                        <tr>
                            <th>Test</th>
                            <th>Passé</th>
                            <th>Score</th>
                            <th>Raison</th>
                        </tr>
                    </thead>
                    <tbody>
                      {Object.values(diagnostic.domain.observatory.advanced).map((value: any, key: any) => {
                        return <tr key={key}>
                          <td className="center">
                            <a target="_blank" href={"https://infosec.mozilla.org/guidelines/web_security#" + value['name']}>{value['name']}</a>
                          </td>
                          {value['pass'] ? <td className="center green">&#10003;</td> : <td className="center red">&#x292C;</td>}
                          <td className="center">{value['score_modifier']}</td>
                          <td className="center">{value['score_description']}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                }
              </>
            }

        </Panel>

        <Panel title="Performances">
          {diagnostic?.tasks?.gtMetrix && <h3><b>{getStatus(diagnostic?.tasks['gtMetrix'])} GT Metrix</b></h3>}
          {!!diagnostic?.domain?.gt_metrix?.results &&
            <>
              <div className="columns">
                <span><b>Crédits restants : </b>{diagnostic?.domain?.gt_metrix?.api_credits}</span>
                <span><b>Rapport complet : </b><a target="_blank" href={diagnostic.domain?.gt_metrix?.results.report_url}>{diagnostic.domain?.gt_metrix?.results.report_url}</a></span>
                <span><b>TTFB : </b>{diagnostic.domain?.gt_metrix?.results.html_load_time}ms</span>
                <span><b>Temps de chargement complet de la page : </b>{diagnostic.domain?.gt_metrix?.results.fully_loaded_time}ms</span>
                <span><b>Taille de la page : </b>{niceBytes(diagnostic.domain?.gt_metrix?.results.page_bytes)}</span>
                <span><b>Nombre de requêtes : </b>{diagnostic.domain?.gt_metrix?.results.page_elements}</span>
                <span><b>Score Page Speed : </b>{diagnostic.domain?.gt_metrix?.results.pagespeed_score}%</span>
                <span><b>Score YSlow : </b>{diagnostic.domain?.gt_metrix?.results.yslow_score}%</span>
              </div>              
            </>
          }

          {(!!diagnostic?.domain?.gt_metrix?.state && diagnostic?.domain?.gt_metrix?.state !== 'completed') && <span><CircularProgress size={20} disableShrink/> Status : {diagnostic?.domain?.gt_metrix?.state}</span>}

          {!!diagnostic?.domain?.gt_metrix?.error && <span><i className="red">&#x292C;</i> {diagnostic?.domain?.gt_metrix?.error}</span>}
        </Panel>

        <Panel title="Sécurité">

          {diagnostic?.tasks?.signalAuth0 && <h3><b>{getStatus(diagnostic?.tasks?.signalAuth0)} Signal Auth0</b></h3>}
          {!!diagnostic?.domain?.signalAuth0?.fullip &&
            <>
              <p><b><i className={getAuth0ScoreColor(diagnostic?.domain?.signalAuth0?.fullip?.score) + ' fs-25'}>&#x26A0;</i> Score : </b>{diagnostic?.domain?.signalAuth0?.fullip?.score}</p>
              <div><b><i className={getAuth0ScoreColor(diagnostic?.domain?.signalAuth0?.fullip?.badip?.score) + ' fs-25'}>&#x26A0;</i> Adresse IP blacklist : </b>
                {diagnostic?.domain?.signalAuth0?.fullip?.badip?.blacklists?.length === 0 && <p>Aucune</p>}
                {diagnostic?.domain?.signalAuth0?.fullip?.badip?.blacklists?.length > 0 && 
                  <>
                    {Object.values(diagnostic?.domain?.signalAuth0?.fullip?.badip?.blacklists).map((value: any, key: any) => {
                      return <span key={key}>
                        {value},&nbsp;
                      </span>
                    })}
                  </>
                }
              </div>

              {/* <p><b>Hostname blacklist : </b>
                {diagnostic?.domain?.signalAuth0?.fullip?.baddomain?.blacklists?.length === 0 && <p>Aucune</p>}
                {diagnostic?.domain?.signalAuth0?.fullip?.baddomain?.blacklists?.length > 0 && 
                  <>
                    {Object.values(diagnostic?.domain?.signalAuth0?.fullip?.baddomain?.blacklists).map((value: any, key: any) => {
                      return <span key={key}>
                        {value},&nbsp;
                      </span>
                    })}
                  </>
                }
              </p> */}

              {diagnostic?.domain?.signalAuth0?.fullip?.history?.activity.length > 0 &&
                <><i className={getAuth0ScoreColor(diagnostic?.domain?.signalAuth0?.fullip?.history?.score) + ' fs-25'}>&#x26A0;</i><b> Activité blacklist</b>
                  <table className="table-border">
                    <thead>
                        <tr>
                            <th>Quand</th>
                            <th>Date</th>
                            <th>Heure</th>
                            <th>Action</th>
                            <th>Blacklist</th>
                        </tr>
                    </thead>
                    <tbody>
                      {Object.values(diagnostic?.domain?.signalAuth0?.fullip?.history?.activity).map((value: any, key: any) => {
                        return <tr key={key}>
                          <td className="center p-5">{moment(value['timestamp']).fromNow()}</td>
                          <td className="center p-5">{moment(value['timestamp']).format("MM/DD/YYYY")}</td>
                          <td className="center p-5">{moment(value['timestamp']).format("HH:mm:ss")}</td>
                          <td className="center p-5">{value['command'] === 'rem' ? <span className="red">Remove</span> : <span className="green">Added</span>}</td>
                          <td className="center p-5">{value['blacklist_change']}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>      
                </>
              }
              <div><br/>
                <b>Système autonome : </b>
                {!!diagnostic?.domain?.signalAuth0?.fullip?.whois?.asn && <p className="m-0">Numéro ASN : {diagnostic.domain.signalAuth0.fullip.whois.asn}</p>}
                {!!diagnostic?.domain?.signalAuth0?.fullip?.whois?.asn_registry && <p className="m-0">Registry : {diagnostic.domain.signalAuth0.fullip.whois.asn_registry}</p>}
                {!!diagnostic?.domain?.signalAuth0?.fullip?.whois?.asn_date && <p className="m-0">Date : {diagnostic.domain.signalAuth0.fullip.whois.asn_date}</p>}
                {!!diagnostic?.domain?.signalAuth0?.fullip?.whois?.asn_country_code && <p className="m-0">Pays : {diagnostic.domain.signalAuth0.fullip.whois.asn_country_code}</p>}
                {!!diagnostic?.domain?.signalAuth0?.fullip?.whois?.asn_description && <p className="m-0">Description : {diagnostic.domain.signalAuth0.fullip.whois.asn_description}</p>}            
              </div> 

              <div><br/>
                <b>Geolocalisation : </b>
                {!!diagnostic?.domain?.signalAuth0?.fullip?.geo?.hostname && <p className="m-0">Hôte : {diagnostic.domain.signalAuth0.fullip.geo.hostname}</p>}
                {!!diagnostic?.domain?.signalAuth0?.fullip?.geo?.continent_names?.fr && <p className="m-0">Continent : {diagnostic.domain.signalAuth0.fullip.geo.continent_names.fr}</p>}
                {!!diagnostic?.domain?.signalAuth0?.fullip?.geo?.country_names?.fr && <p className="m-0">Pays : {diagnostic.domain.signalAuth0?.fullip.geo.country_names.fr}</p>}
                {!!diagnostic?.domain?.signalAuth0?.fullip?.geo?.city_names?.fr && <p className="m-0">Ville : {diagnostic.domain.signalAuth0.fullip.geo.city_names.fr}</p>}
                {!!diagnostic?.domain?.signalAuth0?.fullip?.geo?.time_zone && <p className="m-0">Time zone : {diagnostic.domain.signalAuth0.fullip.geo.time_zone}</p>}            
              </div> 
            </>           
          }

        {diagnostic?.tasks?.dnsblLookup && <h3><b>{getStatus(diagnostic?.tasks?.dnsblLookup)} RBL</b></h3>}
        {!!diagnostic?.domain?.dnsblLookup && Object.values(diagnostic?.domain?.dnsblLookup)?.length > 0 &&
          <>

            {diagnostic?.ping?.v4?.length > 0 &&
              <>
                <a target="_blank" href={"https://talosintelligence.com/reputation_center/lookup?search=" + diagnostic?.ping?.v4[0]?.host}>
                  Voir RBL Cisco
                </a>
                <p>
                  L’adresse IPv4 <b>{diagnostic?.ping?.v4[0].host}</b>&nbsp;
                  est listé sur <b>{diagnostic.domain.dnsblLookup?.listedRblCount}</b>&nbsp;
                  RBLs sur un total de <b>{Object.values(diagnostic?.domain?.dnsblLookup).length - 1}</b> RBLs vérifiées
                </p>
              </>
            }

            {Object.values(diagnostic.domain.dnsblLookup).map((value: any, key: any) => {
              return <p key={key} className="m-0">
                {(!!value['status'] && value['status'] === 'listed') && 
                  <>
                    <i className="green">Listé</i> sur <b>{value['zone']}</b>
                  </>
                }
              </p>
            })}
          </>
        }

        </Panel>

      </div>
  );
} 