import React, { useState } from 'react';
import './App.css';
import Auth from './routes/auth/Auth';
import { Router, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history';
import DashboardContainer from './routes/dashboard/containers/DashboardContainer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoute from './shared/ProtectedRoute';
import { Paper, Tabs, Tab, Button } from '@material-ui/core';
import * as storage from './shared/services/storage';

toast.configure();

const browserHistory = createBrowserHistory();

export const TabContext = React.createContext(0);

function App() {  
  const [tabActive, setActive] = useState(0);

  const changeTab = (event: React.ChangeEvent<{}>, newTab: number) => {
    setActive(newTab);
  };

  const disconnect = () => {
    storage.remove('access_token');

    browserHistory.push('/');
  }

  return (
    <>
      <div className="App">
    
          <Router history={browserHistory}>

            <Switch>
              <Route exact path="/" component={Auth} />

              <ProtectedRoute exact path="/dashboard">

                <Paper square>
                  <Tabs
                    value={tabActive}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    onChange={changeTab}>
                    <Tab label="Domaine" />
                    <Tab label="Adresse IP" />
                    <Tab label="Serveur linux" />
                    <Tab label="Mon compte" />

                    <Button className="disconnect inline-block" onClick={disconnect}>
                      Déconnexion
                    </Button>
                  </Tabs>
                </Paper>

                <TabContext.Provider value={tabActive}>
                  <Route component={DashboardContainer} />  
                </TabContext.Provider>

              </ProtectedRoute>
              
              <Route path="*">
                  404
              </Route>
            </Switch>

          </Router>

      </div>
    </>

  );
}

export default App;
