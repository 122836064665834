import React from 'react';
import MaterialTable from 'material-table';
import { TablePagination } from '@material-ui/core';
import './DiagnosticsComponents.scss';
import { DiagnosticType } from '../../../shared/services/enums'
const moment = require('moment');

export default function DiagnosticsTable(
    {perPage, data, total, currentPage, changePage, showDiagnostic, download, deleteDiagnostic, renew}: any)
    {
    const columns: any = [
      { title: 'Hôte', field: 'host' },
      { 
        title: 'Type',
        field: 'type',
        lookup: { 'DOMAIN': 'Domaine', 'ADDRESS': 'Adresse ip', 'SSH': 'Serveur Linux' },
      },
      { 
        title: 'Diagnostiqué le',
        field: 'created_at',
        render: (value: any) => moment(value).utc(2).format('DD/MM/YYYY'),
    },
    ]

    return (
        <div className="diagnostics">
            <MaterialTable
                title="Diagnostiques"
                columns={columns}
                data={data}
                actions={[
                    (rowData: any) => ({
                        icon: 'get_app',
                        tooltip: 'Télécharger PDF',
                        disabled: !rowData['finished'],
                        onClick: (event, rowData: any) => download(rowData)
                    }),
                    {
                        icon: 'search',
                        tooltip: 'Voir',
                        onClick: (event, rowData: any) => showDiagnostic(rowData._id)
                    },
                    {
                        icon: 'autorenew',
                        tooltip: 'Relancer',
                        hidden: (!!data[0] && data[0].type === DiagnosticType.SSH),
                        onClick: (event, rowData: any) => renew(rowData)
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Supprimer',
                        onClick: (event, rowData: any) => deleteDiagnostic(rowData)
                    }
                ]}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            rowsPerPageOptions={[]}
                            rowsPerPage={perPage}
                            count={total}
                            page={currentPage}
                            onChangePage={(e, page) => {
                                changePage(page);
                            }
                            }
                        />
                    ),
                }}
            />
        </div>
    );
} 