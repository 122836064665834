import React, { useState, useEffect } from 'react';
import api from '../../../shared/services/api'
import * as storage from '../../../shared/services/storage'
import UsersTable from '../components/UsersTable';
import './Account.scss';
import { Card, CardContent, TextField, Button } from '@material-ui/core';
import { toast } from 'react-toastify';

export default function Account() {
    const [users, setUsers] = useState<Map<string, any>>(new Map());
    const [user, setUser] = useState<any>({});

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const me = storage.get('user');

        setUser(JSON.parse(me as string));

        askForUsers();
    }, []);

    useEffect(() => {
        setUsername(user['username'] || '')
    }, [user]);

    
    const onSubmit = async (event: any) => {
        event.preventDefault();

        updateUser({_id: user['id'], username, password})
    }

    const askForUsers = async () => {
        try {
            const result = await api.user.getAll();

            setUsers(new Map<string, any>(result.data.map((user: any) => [user._id, user])));
        } catch(err) {
            console.log(err)
        }
    }

    const updateUser = async (data: any): Promise<any> => {
        try {
            await api.user.update(data);

            toast.success('Informations modifiées', {
                position: toast.POSITION.TOP_RIGHT
            });

            askForUsers();
        } catch(err) {
            toast.error('Une erreur est survenue', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const addUser = async (data: any): Promise<any> => {
        try {
            await api.user.create(data);
            
            askForUsers();
        } catch(err) {
            toast.error('Une erreur est survenue', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const deleteUser = async (data: any): Promise<any> => {
        try {
            await api.user.deleteOne(data['_id']);
            
            askForUsers();
        } catch(err) {
            toast.error('Une erreur est survenue', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (
        <div className="account">

            {(!!user && user['isAdmin']) && <UsersTable
            data={Array.from(users.values())}
            changePage={askForUsers}
            addUser={addUser}
            updateUser={updateUser}
            deleteUser={deleteUser} />}

            {(!!user && !user['isAdmin']) && 
            <Card className="not-admin-wrapper">
                <CardContent>

                    <form onSubmit={onSubmit} autoComplete="off">

                        <TextField required fullWidth={true} onChange={(e) => setUsername(e.target.value)} id="username" label="Login" name="username" />

                        <TextField required fullWidth={true} onChange={(e) => setPassword(e.target.value)} id="password" type="password" label="Mot de passe" name="password" /> <br/><br/> 

                        <Button type="submit" variant="contained" color="primary">
                            Valider
                        </Button>

                    </form>

                </CardContent>
            </Card>}

        </div>
    );
}