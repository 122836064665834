import { instance } from '.';

export const diagnoze = async (params: object) => {
    return instance.post(`diagnostics/diagnoze`, params);
}

export const getAll = async (page: number, take: number) => {
    return instance.get(`diagnostics?page=${page}&take=${take}`);
}

export const deleteDiagnostic = async (id: string) => {
    return instance.delete(`diagnostics/${id}`);
}

export const download = async (id: string) => {
    return instance.get(`diagnostics/download/${id}`,
        {
            responseType: 'arraybuffer',
            headers: {
            'Accept': 'application/pdf'
            }
        });
}

export const getOne = async (id: string) => {
    return instance.get(`diagnostics/${id}`);
}