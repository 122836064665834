import React from 'react';
import './DiagnosticsComponents.scss';
import { Divider, CircularProgress } from '@material-ui/core';
import { Panel } from '../../../shared/ui/components/Panel';

export default function Address({diagnostic}: any) {
    const getStatus = (status: string) => {
        if(status === 'SUCCESS') {
            return <i className="green">&#10003;</i>;
        } else if(status === 'PENDING') {
            return <CircularProgress size={20} disableShrink/>;
        } else if(status === 'FAILURE'){
           return <i className="red">&#x292C;</i>;
        }
      }
      
    return (
        <div className="ssh">
            <Panel title="Informations">

                {!!diagnostic?.ssh?.memInfo &&
                    <><h3><b>{getStatus(diagnostic?.tasks['memInfo'])} Mémoire</b></h3>
                        {!!diagnostic?.ssh?.memInfo?.total && <p className="m-0"><b>Total : </b> {diagnostic.ssh.memInfo.total} kB</p>}
                        {!!diagnostic?.ssh?.memInfo?.free && <p className="m-0"><b>Libre : </b> {diagnostic.ssh.memInfo.free} kB</p>}
                        
                        <br/>
                        <Divider/>
                    </>   
                }

                {!!diagnostic?.ssh?.upTime &&
                    <><h3><b>{getStatus(diagnostic?.tasks['upTime'])} Uptime</b></h3>
                        {!!diagnostic?.ssh?.upTime?.upDate && <p><b>Serveur démarré depuis : </b> {diagnostic.ssh.upTime.upDate} jours</p>}
                        <b>Charge serveur</b>
                        {!!diagnostic?.ssh?.upTime?.oneMin && <p>{diagnostic.ssh.upTime.oneMin} depuis une minute</p>}
                        {!!diagnostic?.ssh?.upTime?.fiveMin && <p>{diagnostic.ssh.upTime.fiveMin} depuis 5 minutes</p>}
                        {!!diagnostic?.ssh?.upTime?.fifteenMin && <p>{diagnostic.ssh.upTime.fifteenMin} depuis 15 minutes</p>}           
                        
                        <br/>
                        <Divider/>
                    </>
                }

                {!!diagnostic?.ssh?.cpuInfo &&
                    <><h3><b>{getStatus(diagnostic?.tasks['cpuInfo'])} Processeurs</b></h3>
                        {diagnostic?.ssh?.cpuInfo?.nbProcessors && <p className="m-0"><b>Nombre de threads : </b>{diagnostic.ssh.cpuInfo.nbProcessors}</p>}
                        <br/>
                        <Divider/>      
                    </>
                }
                

                {!!diagnostic?.ssh?.ips &&
                    <><h3><b>{getStatus(diagnostic?.tasks['ips'])} Interfaces</b></h3>
                        {diagnostic?.ssh?.ips?.inet?.length > 0 && 
                            <>
                                <ul>
                                    {Object.values(diagnostic.ssh.ips.inet).map((value: any, key: any) => {
                                        return <li key={key}>{value}</li>
                                    })}
                                </ul>
                            </>
                        }          
                    </>
                }

            </Panel>
        </div>
    );
} 