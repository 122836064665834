import { environment } from "../../../environment";
import io from 'socket.io-client';

const connectionOptions =  {
    "reconnection" : true,
    "reconnection limit" : 1000,
    "max reconnection attempts": "Infinity",
    "timeout" : 10000,
    "transports" : ['websocket', 'polling', 'flashsocket']
};

export const socket = io(environment.apiUrl, connectionOptions);