import React from 'react';
import './DiagnosticsComponents.scss';
import { Divider, CircularProgress } from '@material-ui/core';
import { Panel } from '../../../shared/ui/components/Panel';
import { getAuth0ScoreColor } from '../../../shared/helpers';
const moment = require('moment');

export default function Address({diagnostic}: any) {

  const getStatus = (status: string) => {
    if(status === 'SUCCESS') {
        return <i className="green">&#10003;</i>;
    } else if(status === 'PENDING') {
        return <CircularProgress size={20} disableShrink/>;
    } else if(status === 'FAILURE'){
       return <i className="red">&#x292C;</i>;
    }
  }
  
    return (
        <div className="address">

        <Panel title="Analyse DNS">
          {diagnostic?.tasks?.whoisIp && <h3><b>{getStatus(diagnostic?.tasks['whoisIp'])} Whois RIPE/ARIN</b></h3>}
          {!!diagnostic?.whois &&
            <>
              {!!diagnostic?.whois?.orgName && <p>Organisation : {diagnostic?.whois?.orgName}</p>}
              {!diagnostic?.whois?.orgName && <p>Aucune organisation trouvée.</p>}
            </>
          }
        </Panel>

        <Panel title="Analyse réseau">
          {diagnostic?.tasks?.pingIp && <h3><b>{getStatus(diagnostic?.tasks['pingIp'])} Ping</b></h3>}
          {!!diagnostic?.ping &&
            <>
              {!!diagnostic?.ping && <p className="m-0"><b>IPv4 : </b>{diagnostic?.ping?.host} | ping : {diagnostic?.ping?.ttl}ms</p>}
              {!diagnostic?.ping && <p className="m-0">Le ping à échoué.</p>}

              <br/>
              <Divider />
            </>
          }

          {diagnostic?.tasks?.tracerouteIp && <h3><b>{getStatus(diagnostic?.tasks['tracerouteIp'])} Traceroute</b></h3>}
          {!!diagnostic?.address?.traceroute &&
            <>
              {!!diagnostic?.address?.traceroute && 
                <>
                  {Object.values(diagnostic.address.traceroute).map((value: any, key: any) => {
                    const { hop, ip, rtt1 } = value;

                    return <p key={key} className="m-0"><b>hop : </b>{hop} | <b>IP : </b> {ip} | <b>temps : </b> {rtt1}</p>
                  })}

                  <br/>
                  <Divider />
                </>
              }
            </>
          }

          {diagnostic?.tasks?.reverse && <h3><b>{getStatus(diagnostic?.tasks['reverse'])} Recherche inverse</b></h3>}
          {!!diagnostic?.address?.reverse &&
            <>
                {diagnostic?.address?.reverse?.length && 
                  <>
                    {Object.values(diagnostic.address.reverse).map((value: any, key: any) => {
                      return <p key={key} className="m-0">{value}</p>
                    })}
                  </>
                }
            </>
          }

        </Panel>

        <Panel title="Sécurité">
          {diagnostic?.tasks?.signalAuth0Ip && <h3><b>{getStatus(diagnostic?.tasks['signalAuth0Ip'])} Signal Auth0</b></h3>}
          {!!diagnostic?.address?.signalAuth0?.fullip &&
            <>
              <p><b><i className={getAuth0ScoreColor(diagnostic?.address?.signalAuth0?.fullip?.score) + ' fs-25'}>&#x26A0;</i> Score : </b>{diagnostic?.address?.signalAuth0?.fullip?.score}</p>
              <div><b><i className={getAuth0ScoreColor(diagnostic?.address?.signalAuth0?.fullip?.badip?.score) + ' fs-25'}>&#x26A0;</i> Adresse IP blacklist : </b>
                {diagnostic?.address?.signalAuth0?.fullip?.badip?.blacklists?.length === 0 && <p className="m-0">Aucune</p>}
                {diagnostic?.address?.signalAuth0?.fullip?.badip?.blacklists?.length > 0 && 
                  <>
                    {Object.values(diagnostic?.address?.signalAuth0?.fullip?.badip?.blacklists).map((value: any, key: any) => {
                      return <span key={key}>
                        {value},&nbsp;
                      </span>
                    })}
                  </>
                }
              </div>

              {/* <p><b>Hostname blacklist : </b>
                {diagnostic?.address?.signalAuth0?.fullip?.badaddress?.blacklists?.length === 0 && <p>Aucune</p>}
                {diagnostic?.address?.signalAuth0?.fullip?.badaddress?.blacklists?.length > 0 && 
                  <>
                    {Object.values(diagnostic?.address?.signalAuth0?.fullip?.badaddress?.blacklists).map((value: any, key: any) => {
                      return <span key={key}>
                        {value},&nbsp;
                      </span>
                    })}
                  </>
                }
              </p> */}

              {diagnostic?.address?.signalAuth0?.fullip?.history?.activity.length > 0 &&
                <><i className={getAuth0ScoreColor(diagnostic?.address?.signalAuth0?.fullip?.history?.score) + ' fs-25'}>&#x26A0;</i><b> Activité blacklist</b>
                  <table className="table-border">
                    <thead>
                        <tr>
                            <th>Quand</th>
                            <th>Date</th>
                            <th>Heure</th>
                            <th>Action</th>
                            <th>Blacklist</th>
                        </tr>
                    </thead>
                    <tbody>
                      {Object.values(diagnostic?.address?.signalAuth0?.fullip?.history?.activity).map((value: any, key: any) => {
                        return <tr key={key}>
                          <td className="center p-5">{moment(value['timestamp']).fromNow()}</td>
                          <td className="center p-5">{moment(value['timestamp']).format("MM/DD/YYYY")}</td>
                          <td className="center p-5">{moment(value['timestamp']).format("HH:mm:ss")}</td>
                          <td className="center p-5">{value['command'] === 'rem' ? <span className="red">Remove</span> : <span className="green">Added</span>}</td>
                          <td className="center p-5">{value['blacklist_change']}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>      
                </>
              }

              <div><br/>
                <b>Système autonome : </b>
                {!diagnostic?.address?.signalAuth0?.fullip?.whois && <p className="m-0">Aucune informations</p>}
                {diagnostic?.address?.signalAuth0?.fullip?.whois?.asn && <p className="m-0">Numéro ASN : {diagnostic?.address?.signalAuth0?.fullip?.whois?.asn}</p>}
                {diagnostic?.address?.signalAuth0?.fullip?.whois?.asn_registry && <p className="m-0">Registry : {diagnostic?.address?.signalAuth0?.fullip?.whois?.asn_registry}</p>}
                {diagnostic?.address?.signalAuth0?.fullip?.whois?.asn_date && <p className="m-0">Date : {diagnostic?.address?.signalAuth0?.fullip?.whois?.asn_date}</p>}
                {diagnostic?.address?.signalAuth0?.fullip?.whois?.asn_country_code && <p className="m-0">Pays : {diagnostic?.address?.signalAuth0?.fullip?.whois?.asn_country_code}</p>}
                {diagnostic?.address?.signalAuth0?.fullip?.whois?.asn_description && <p className="m-0">Description : {diagnostic?.address?.signalAuth0?.fullip?.whois?.asn_description}</p>}            
              </div> 

              <div><br/>
                <b>Geolocalisation : </b>
                {!diagnostic?.address?.signalAuth0?.fullip?.geo && <p className="m-0">Aucune informations</p>}
                {diagnostic?.address?.signalAuth0?.fullip?.geo?.hostname && <p className="m-0">Hôte : {diagnostic?.address?.signalAuth0?.fullip?.geo?.hostname}</p>}
                {diagnostic?.address?.signalAuth0?.fullip?.geo?.continent_names?.fr && <p className="m-0">Continent : {diagnostic?.address?.signalAuth0?.fullip?.geo?.continent_names?.fr}</p>}
                {diagnostic?.address?.signalAuth0?.fullip?.geo?.country_names?.fr && <p className="m-0">Pays : {diagnostic?.address?.signalAuth0?.fullip?.geo?.country_names?.fr}</p>}
                {diagnostic?.address?.signalAuth0?.fullip?.geo?.city_names?.fr && <p className="m-0">Ville : {diagnostic?.address?.signalAuth0?.fullip?.geo?.city_names?.fr}</p>}
                {diagnostic?.address?.signalAuth0?.fullip?.geo?.time_zone && <p className="m-0">Time zone : {diagnostic?.address?.signalAuth0?.fullip?.geo?.time_zone}</p>}            
              </div>

              <br/>
              <Divider />
            </>           
          }

          {diagnostic?.tasks?.openPorts && <h3><b>{getStatus(diagnostic?.tasks['openPorts'])} Ports ouverts</b></h3>}
          {diagnostic?.address?.openPorts?.data?.items?.length > 0 &&
            <>
              {Object.values(diagnostic.address.openPorts.data.items).map((value: any, key: any) => {
                return <ul key={key} className="m-0">
                  <li>{value?.port}</li>
                </ul>
              })}

              <br/>
              <Divider />
            </>
          }

          {diagnostic?.tasks?.dnsblLookupIp && <h3><b>{getStatus(diagnostic?.tasks['dnsblLookupIp'])} RBL</b></h3>}
          {!!diagnostic?.address?.dnsblLookup && Object.values(diagnostic?.address?.dnsblLookup)?.length > 0 &&
          <>
            <b>RBL Cisco : </b>
            <a target="_blank" href={"https://talosintelligence.com/reputation_center/lookup?search=" + diagnostic.host}>
              https://talosintelligence.com/reputation_center/lookup?search={diagnostic.host}
            </a>
            
            <p>
              L’adresse IPv4 <b>{diagnostic?.ping?.host}</b>&nbsp;
              est listé sur <b>{diagnostic.address.dnsblLookup.listedRblCount}</b>&nbsp;
              RBLs sur un total de <b>{Object.values(diagnostic?.address?.dnsblLookup).length - 1}</b> RBLs vérifiées
            </p>

            {Object.values(diagnostic.address.dnsblLookup).map((value: any, key: any) => {
              return <p key={key} className="m-0">
                {(!!value['status'] && value['status'] === 'listed') && 
                  <>
                    <i className="green">Listé</i> sur <b>{value['zone']}</b>
                  </>
                }
              </p>
            })}
          </>
          }
          
          </Panel>
        </div>
    );
} 