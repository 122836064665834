import React, { useEffect, useState, useContext, useRef } from 'react';
import api from '../../../shared/services/api';
import { DiagnosticType } from '../../../shared/services/enums'
import DiagnosticsTable from '../components/DiagnosticsTable';
import './DashboardContainer.scss';
import { TextField, makeStyles, createStyles, Theme, Button, Card, CardContent, LinearProgress } from '@material-ui/core';
import Diagnostic from '../components/Diagnostic';
import Account from '../../account/containers/Account';
import { TabContext } from '../../../App';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import { getDiagRealName, getDiagColor } from '../../../shared/helpers';
import { FilterButton } from '../../../shared/ui/components/FilterButton';
import { ConfirmationModal } from '../../../shared/ui/components/ConfirmationModal';

const moment = require('moment');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
      },
    },
  }),
);

const DIAGNOSTICS = {
    'DOMAIN': [
        {
            name: 'Analyse réseau',
            diag: [
                'ping',
                'traceroute',
            ]
        },
        {
            name: 'Analyse DNS',
            diag: [
                'resolve',
                'whois',
                'digTxt',
                'digMx',
                'digNs',
            ]
        },
        {
            name: 'Compliance WEB',
            diag: [
                'whatCMS',
                'observatory',
                'hstsPreload',
                'sslLabs',
                'headers',
            ]
        },
        {
            name: 'Sécurité',
            diag: [
                'dnsblLookup',
                'signalAuth0',
            ]
        },
        {
            name: 'Performances',
            diag: [
                'gtMetrix'
            ]
        },

    ],
    'ADDRESS': [
        {
            name: 'Analyse réseau',
            diag: [
                'pingIp',
                'tracerouteIp',
            ]
        },
        {
            name: 'Analyse DNS',
            diag: [
                'whoisIp',
            ]
        },
        {
            name: 'Sécurité',
            diag: [
                'reverse',
                'dnsblLookupIp',
                'signalAuth0Ip',
                'openPorts'
            ]
        },
    ],
    'SSH': [
        {
            name: 'Informations',
            diag: [
                'ips',
            'upTime',
            'cpuInfo',
            'memInfo'
            ]
        },
    ]
}
 
export default function DashboardContainer() {
    const tab = useContext(TabContext);
    const tabRef = useRef(tab);
    const classes = useStyles();

    const [perPage, setPerPage] = useState<number>(5);
    const [pending, setPending] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(0);

    const [data, setData] = useState<Map<string, any>>(new Map());
    const [total, setTotal] = useState<number>(0);
    const [diagnostic, setDiagnostic] = useState<any>(null);

    const [host, setHost] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [login, setLogin] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [diagInProgress, setDiagInProgress] = useState<any>(null);

    const [toggleModal, setToggleModal] = useState(false);
    const [modalParameters, setModalParameters] = useState(() => {
        return {
            header: '',
            content: '',
            callback: (data: any): void => {},
            closeModal: (data: any): void => {}
        }
    });

    useEffect(() => {
      tabRef.current = tab;
    });

    const getDiagType = (): DiagnosticType => {
        switch(tabRef.current) {
            case 0:
                return DiagnosticType.Domain;
            case 1:
                return DiagnosticType.Address;
            case 2:
                return DiagnosticType.SSH;
            default:
                return DiagnosticType.Domain;
        }
    }

    const getDefaultFilterState = () => {
        const map = new Map();

        for(const diag of DIAGNOSTICS[getDiagType()]) {
            let defaultState = {};
            const tasks = Object.values(diag);

            for(const task of tasks[1]) {
                defaultState = {
                    [task]: true,
                    ...defaultState
                };
            }

            map.set(tasks[0], defaultState);
        }

        return map;
    }

    const [filterButtonstate, setFilterButtonState] = useState<any>(getDefaultFilterState);

    useEffect(() => {
        setDiagnostic(null);

        if(tab < 3) {
            setCurrentPage(0);
            askForDiagnostics(currentPage);

            setFilterButtonState(getDefaultFilterState);
        }
    }, [tab])

    useEffect(() => {
        api.socket.on('completed', (diag: any) => {
            setDiagInProgress(diag);

            askForDiagnostics(currentPage);
        });

        api.socket.on('diagnostics-paginated', (response: any) => {
            setData(new Map<string, any>(response.data.map((diagnostic: any) => [diagnostic._id, diagnostic])));
            setCurrentPage(response.page)
            setTotal(response.total);
            setPerPage(response.perPage);
        });

        return () => {
          api.socket.off('completed');
          api.socket.off('diagnostics-paginated');
        }
    }, []);

    useEffect(() => {
        if(diagInProgress?._id === diagnostic?._id) {
            setDiagnostic(diagInProgress);
        }
    }, [diagInProgress]);

    const askForDiagnostics = (page: number): void => {
      api.socket.emit('diagnostics-paginated', {page, perPage, type: getDiagType()});
    }

    const showDiagnostic = (id: string): void => {
      data.has(id) && setDiagnostic(data.get(id));
    }

    const getFiltersValues = () => {
        let response: any[] = [];

        filterButtonstate.forEach((t: any) => {
            response = [...response, ...Object.keys(t).filter(k => t[k])];
        });

        return response;
    }

    const onSubmit = async (event: any) => {
        event.preventDefault();

        try {
            setPending(true);

            await api.diag.diagnoze({ host, email, login, password, description, type: getDiagType(), filters: getFiltersValues() });
        } catch(err) {
            toast.error('Une erreur est survenue.', {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        setPending(false);
    };

    const displayDiagnostic = () => {
        if(!!diagnostic?.type) {
            return <Diagnostic diagnostic={diagnostic}/>
        } else {
            return (
                <div className="empty-right">
                    <img src="/logo-big.png" className="logo" />
                </div>
            )
        }
    }

    const deleteDiagnosticCallback = (data: any) => async () => {
        closeModal();

        try {
            await api.diag.deleteDiagnostic(data._id);

            toast.success('Le diagnostic a bien été supprimé', {
                position: toast.POSITION.TOP_RIGHT
            });

            askForDiagnostics(currentPage);
        } catch(err) {
            toast.error('Erreur lors de la suppression du diagnostic', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const deleteDiagnostic = async (data: any) => {
        setModalParameters({header: 'Suppression', content: 'Voulez-vous supprimer ce diagnostic ?', callback: deleteDiagnosticCallback(data), closeModal: closeModal});
        setToggleModal(true);
    }

    const renewCallback = (data: any) => async () => {
        closeModal();
        
        try {
            setPending(true);

            await api.diag.diagnoze({ host: data.host, email, description: data.description, type: getDiagType(), filters: getFiltersValues()});
        } catch(err) {
            toast.error('Erreur lors du diagnostic', {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        setPending(false);
    }

    const closeModal = () => {
        setToggleModal(false);
    }

    const renew = async (data: any) => {
        setModalParameters({header: 'Relancer', content: 'Voulez-vous relancer ce diagnostic ?', callback: renewCallback(data), closeModal: closeModal});
        setToggleModal(true);
    }

    const download = async (data: any) => {
        try {
            const res = await api.diag.download(data._id);
            
            const blob = new Blob([res.data], {type: "application/pdf;charset=utf-8"});

            saveAs(blob, `Diagnostic_${data.host}_${moment(data.created_at).utc(2).format('DD/MM/YYYY')}.pdf`);
        } catch(err) {
            toast.error('Erreur lors du téléchargement du PDF', {
                position: toast.POSITION.TOP_RIGHT
            });
        } 
    }

    const filterButtonChange = (t: any, i: any) => () => {
        filterButtonstate.get(t)[i] = !filterButtonstate.get(t)[i];
    };

    return (
        <>
            {tab === 3 && <Account />}

            <ConfirmationModal modalParameters={modalParameters} toggleModal={toggleModal}/>

            <div className="dashboard">

                {tab < 3 && 
                    <>
                        <div className="left">

                            <Card className="form-wrapper">
                                <CardContent>

                                <form onSubmit={onSubmit} className={classes.root} autoComplete="on">
                                    <TextField fullWidth={true} required onChange={(e) => setHost(e.target.value)} id="host" label={tab === 1 ? 'Adresse IP' : 'Hôte'} name="host" />

                                    {tab === 2 &&
                                        <>
                                            <TextField required={!!password} fullWidth={true} onChange={(e) => setLogin(e.target.value)} id="login" label="Login" name="login" />

                                            <TextField required={!!login} fullWidth={true} onChange={(e) => setPassword(e.target.value)} id="password" type="password" label="Mot de passe" name="password" /> <br/><br/> 
                                        </>
                                    }

                                    <TextField fullWidth={true} onChange={(e) => setEmail(e.target.value)}  label="Email" id="email" name="email" />

                                    <TextField fullWidth={true} multiline={true} onChange={(e) => setDescription(e.target.value)} label="Description" id="description" name="description" rows={3} />

                                    <div className="filters-container">
                                        {!!filterButtonstate && [...filterButtonstate.entries()].map((value: any, key: any) => {
                                            return (<div className="filters-wrapper" key={key}>
                                                <b>{value[0]} </b>&nbsp;

                                                {Object.keys(value[1]).map((t: any, index: any) => {

                                                    return <span key={index}>
                                                        <FilterButton
                                                            color={getDiagColor(t)}
                                                            changeHandler={filterButtonChange(value[0], t)}
                                                            isActivated={value[1][t]}
                                                            name={value}>{getDiagRealName(t)}</FilterButton>
                                                    </span>
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <br/>
                                    
                                    <Button type="reset" variant="contained" color="default">
                                        Réinitialiser
                                    </Button>&nbsp;

                                    <Button disabled={pending} type="submit" variant="contained" color="primary">
                                        Diagnostiquer
                                    </Button>

                                </form>

                                {pending && <><br/><LinearProgress/></>}

                                </CardContent>
                            </Card>

                            {!!data && 
                                <DiagnosticsTable
                                perPage={perPage}
                                data={Array.from(data.values())}
                                total={total}
                                currentPage={currentPage}
                                changePage={askForDiagnostics}
                                showDiagnostic={showDiagnostic}
                                download={download}
                                deleteDiagnostic={deleteDiagnostic}
                                renew={renew}
                            />}
                        </div>

                        <div className="right">
                            {displayDiagnostic()}
                        </div>
                    </>
                }

            </div>
        </>
    );
}
