import React from "react";
import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, CircularProgress } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export function Panel(props: any) {
    return (
        <ExpansionPanel defaultExpanded={true} disabled={props.disabled} >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} >
                <Typography variant="h5" component="h2" className="heading">
                    {props.title}
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Typography component={'span'}>
                    {props.children}
                </Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
}