import React, { useState } from 'react';
import { useHistory } from 'react-router';
import api from '../../shared/services/api';
import { toast } from 'react-toastify';
import { Card, CardContent, TextField, Button } from '@material-ui/core';
import './Auth.scss';
    
export default function Auth() {
    let history = useHistory();

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = async (e: any) => { 
        e.preventDefault();

        try {
            await api.auth.login({username: login, password});

            await api.user.me();

            history.push('/dashboard')
        } catch(err) {
            toast.error('Mauvais couple login / mot de passe', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    return (
        <div className="auth">
            <div className="auth-wrapper">
                <img src="/logo-big.png" className="logo" />

                <Card className="form-wrapper">
                    <CardContent>

                        <h1 className="login-title">Connexion</h1>

                        <form onSubmit={onSubmit} autoComplete="on">

                            <TextField required onChange={(e) => setLogin(e.target.value)} id="username" label="Login" name="username" />

                            <TextField required onChange={(e) => setPassword(e.target.value)} id="password" type="password" label="Mot de passe" name="password" />

                            <Button type="submit" variant="contained" color="primary">
                                Se connecter
                            </Button>

                        </form>

                    </CardContent>
                </Card>
            </div>
        </div>
    );
}